import React from 'react'
import styled from 'styled-components'
import { colors, md } from '../common'

export default () => (
  <Wrap>
    <h1>CUT SCALE</h1>
    <div css={`display:flex;${md}{flex-direction:column}`}>

      <div css={`flex:1;`}>
        <div css={`display:flex;margin: 0 .5px;`}>
          <Num>0</Num>
        </div>
        <Type>
          <p>AGS IDEAL</p>
        </Type>
        </div>

        <div css={`flex:1;`}>
          <div css={`display:flex;margin: 0 .5px;`}>
            <Num>1</Num>
          </div>
          <Type>
            <p>AGS EXCELLENT</p>
          </Type>
          </div>

          <div css={`flex:1;`}>
            <div css={`display:flex;margin: 0 .5px;`}>
              <Num>2</Num>
            </div>
            <Type>
              <p>AGS VERY GOOD</p>
            </Type>
            </div>

            <div css={`flex:2;`}>
              <div css={`display:flex;margin: 0 .5px;`}>
                <Num>3</Num>
                <Num>4</Num>
              </div>
              <Type>
                <p>AGS GOOD</p>
              </Type>
              </div>

              <div css={`flex:3;`}>
                <div css={`display:flex;margin: 0 .5px;`}>
                  <Num>5</Num>
                  <Num>6</Num>
                  <Num>7</Num>
                </div>
                <Type>
                  <p>AGS GOOD</p>
                </Type>
                </div>

                <div css={`flex:3;`}>
                  <div css={`display:flex;margin: 0 .5px;`}>
                    <Num>8</Num>
                    <Num>9</Num>
                    <Num>10</Num>
                  </div>
                  <Type>
                    <p>AGS POOR</p>
                  </Type>
                  </div>

      </div>
    <p>To learn more about diamonds and cut, watch our video on Underwood’s Diamonds.</p>
  </Wrap>
)

const Wrap = styled.div`
  background-color: ${colors.mediumBlack};
  color:white;
  text-align:center;
  padding: 8rem;

  ${md} {
    padding: 1rem;
  }
`

const Num = styled.p`
  background-color:${colors.green};
  color:white;
  padding:.5rem;
  margin:0;
  width:100%;
  border:2px solid white;
`

const Type = styled.div`
  background-color:white;
  padding:.5rem;
  margin:.5px;
  color:${colors.greyDarkest};
  min-height: 45px;
  min-width:90px;

  p {
    font-size: 12px;
  }
`
