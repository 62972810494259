import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import JoinUs from '../sections/joinUs'
import GrowDiamond from '../sections/growADiamond'
import Search from '../sections/de-diamond-search'
import Cut from '../sections/de-cut'
import Color from '../sections/de-color'
import FancyColor from '../sections/de-color-scale'
import Clarity from '../sections/de-clarity'
import CutScale from '../sections/de-cut-scale'
import GreenBar from '../sections/greenBar3'
import { PageHead, colors, md } from '../common'

export default () => (
  <Layout>
    <SEO
      title="Diamond Education"
      description="Underwood’s has the most AGS Registered Jewelers in Jacksonville and Ponte Vedra, FL. Our staff can help you learn everything you need to know about diamonds and jewelry. Learn about the Four C’s here!" />
    <PageHead>
      <h1>DIAMOND EDUCATION</h1>
      <p>Diamonds were formed deep in the earth more than a billion years ago under high pressure and high temperatures. Diamonds are made up of only one element… carbon, and are 58 times harder than anything else on earth. Diamonds are extremely rare. The average yield of a diamond mine is only one part diamond to every 1,000,000 parts host rock. Diamonds are found in a rough state and then purchased by diamantaires to cut, polish and sell to the open market.</p>
    </PageHead>
    <GreenBar />
    <div css={`text-align:center;padding:2rem;`}>
      <h1 css={`color:${colors.green};text-transform:uppercase;`}>How to Buy a Diamond</h1>
      <iframe title={'youtube video embed'} css={`border:0;${md}{width:100%;}`} width="560" height="315" src="https://www.youtube.com/embed/_wxRRdLCYh8" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
    <Search />
    <Cut />
    <CutScale />
    <Color />
    <FancyColor />
    <Clarity />
    <GrowDiamond />
    <JoinUs />
  </Layout>
)
