import React from 'react'
import styled from 'styled-components'
import { colors, md } from '../common'

export default () => {
  return(
  <Wrap>
  <div css={`margin:auto;width:40%;padding:4rem;${md}{padding:1rem;width:90%;}`}>
    <img css={`width:100%;max-width:150px;`} src="https://s3.amazonaws.com/underwoods/Diamond+Education/chart-cut01.png" alt="diamond light refration guide" />
    <p>ASET images and how they should and should not look:</p>
    <img css={`width:100%`} src="https://s3.amazonaws.com/underwoods/Diamond+Education/chart-cut03-ideal.png" alt="ideal cut light refratcion guide" />
    <img css={`width:100%`}  src="https://s3.amazonaws.com/underwoods/Diamond+Education/chart-cut03-notideal.png" alt="not ideal cut light refratcion guide" />
  </div>
  <div css={`width:50%;${md}{width:100%;}`}>
      <h1 css={`text-transform:uppercase;`}>Cut</h1>
      <br />
      <p>Cut is the only diamond quality that is controlled by man and can affect the cost of a diamond up to 50%. Therefore, making it the most important of the Four C’s. A poorly cut diamond will be on the low end of the price range and will also not be as beautiful or sparkle as much as a diamond cut to ideal proportions. This is something you have to see in person by comparing them side by side, because there are no two diamonds alike, even though their grades might be exactly the same. Diamond cutters will be paid significantly more for a 1.00ct. diamond than a diamond, let’s say that is .98ct. Many of them will using cutting techniques in order to make these “magic numbers”, by displacing the weight of the diamond to other areas. This means, you are getting a diamond that isn’t cut as well and will not be as beautiful. An ideal cut American Gem Society (AGS) 1.00ct. round brilliant diamond should measure 6.5mm in diameter.</p>
      <p>To delve even deeper into cut grading, we should mention Light Performance. Through the Angular Spectrum Evaluation Tool (ASET) and non-contact optical scanning technology we can now measure how well a diamond performs. Or in layman’s terms, how sparkly a diamond is. The better a diamond is cut, the more it will shine, the bigger and more beautiful it will look. Underwood’s is the only company in Jacksonville who buys diamonds with an ASET device, showing the diamond buyers immediately how well the diamond will perform.</p>
      <p>Think of a diamond like a window or a prism. The light should go in, reflect to other facets of the diamond and then return back out through the top. In the ASET image you want to see mostly the color red which is light being reflected, some blue color which shows contrast (because you cannot have refraction without contrast or it would look like a head light!) and a little bit of the color green which shows the light reflected at a different angle. This image should look proportional, similar to a snowflake. When you see the colors white, grey or black, that means the light is leaking and the diamond was not cut proportionally. Simply said, the diamond won’t sparkle as much!</p>
      <p>Once the diamond is in our AGS accredited gem lab in Jacksonville, FL we put it on a non-contact optical scanner which measures the nine factors which can compromise light performance and calculates a light performance grade automatically (zero being the best and 10 being the worst). We then decide whether we want to keep or reject the diamond. Underwood’s diamond buyers reject an average of 95 out of 100 diamonds.</p>
      <p>We are the only store in Jacksonville to use this technology and one of the very few in the entire United States. We encourage you to bring your own diamonds into our stores in Jacksonville and Ponte Vedra Beach, FL to look at them under our ASET devices!</p>
    </div>
  </Wrap>
)}

const Wrap = styled.div`
  background-color:${colors.greyDarkest};
  padding: 4rem;
  color: white;
  text-align: center;
  display: flex;

  p {
    line-height: 22px;
  }

  ${md} {
    padding: 1rem;
    flex-direction:column;
  }
`
