import React from 'react'
import styled from 'styled-components'
import { colors, md } from '../common'

const scaleColors = ["Fancy Light", "Fancy", "Fancy Dark", "Fancy Intense", "Fancy Deep", "Fancy Vivid"]

export default () => (
  <Wrap>
    <h1>FANCY COLOR SCALE</h1>
    <p>The general fancy-colored diamond grading scale is as follows:</p>
    <div css={`display:flex;justify-content:center;${md}{flex-direction:column}`}>
      {scaleColors.map((c, index) => {
        return  <div key={index}>
                  <p css={`padding: .5rem;margin:2px;color:${colors.green};background-color:white;`}>{c}</p>
                </div>
      })}
    </div>
    <p>Fancy grading is very specialized and takes a highly trained gemologist in a reputable lab. Fancy colored diamonds can sometimes, but not always, have more value than a colorless diamond. For example, red and blue diamonds are extremely rare, especially when the color is more saturated.</p>
  </Wrap>
)

const Wrap = styled.div`
  background-color:${colors.greenDark};
  color:white;
  text-align:center;
  padding:4rem 8rem;

  ${md} {
    padding: 1rem;
  }
`
