import React from 'react'
import { A, Card, Wrap } from '../common/greenBar'

export default () => (
  <Wrap>
    <Card>
      <A to="/diamond-calculator">
        <h2>Diamond CALCULATOR</h2>
        <div>
          <img src="https://s3.amazonaws.com/underwoods/Diamond+Education/icon-diamondcalculator.png" alt="diamond calculator icon" />
        </div>
        <p>Use our diamond calculator</p>
      </A>
    </Card>

    <Card css={`border-left:1px solid white;border-right:1px solid white;`}>
      <A to="/diamond-education">
        <h2>Diamond Education</h2>
        <div>
          <img src="https://s3.amazonaws.com/underwoods/Diamond+Education/icon-diamondeducation.png" alt="diamond education icon" />
        </div>
        <p>View Our Diamond Education</p>
      </A>
    </Card>

    <Card>
      <A to="/our-diamond">
        <h2>The Underwoods Diamond</h2>
        <div>
          <img src="https://s3.amazonaws.com/underwoods/icon_our-diamond.png" alt="our diamond icon" />
        </div>
        <p>View our diamond</p>
      </A>
    </Card>

  </Wrap>
)
