import React from 'react'
import { colors } from '../common'

export default () => (
  <div css={`background-color:${colors.greyLight};display:flex;padding:4rem;`}>
    <div css={`width:60%;text-align:center;color:${colors.greyDarker};line-height:24px;margin:auto;`}>
      <h1 css={`color:${colors.green};text-transform:uppercase;`}>your diamond search</h1>
      <p>If you are just starting your search for a diamond or engagement ring, you should familiarize yourself with the Four C’s which stand for cut, color, clarity and carat. The diamond industry standard is to use the Gemological Institute of America (GIA) grading scale. However, Underwood’s goes one step further and also grades our diamonds to the stricter standards of the American Gem Society (AGS).</p>
      <p>GIA and AGS are both non-profit organizations that were founded by the same person, Robert M. Shipley, in 1931 and 1934. AGS has a ten grade cut grading system that goes much further than GIA’s five cut grades, allowing them to be the leaders of cut grading in the World. AGS uses a scale from zero to ten, with zero being the best and ten being the worst. By using ten cut grades instead of five, it allows Underwood’s to find the best cut diamonds for our customers. It’s about beauty and science.</p>
    </div>
    <div css={`width:40%;margin:auto;`}>
      <img css={`margin-right:-50px;`} src="https://s3.amazonaws.com/underwoods/Diamond+Education/diamonds.png" alt="cut and rough diamonds" />
    </div>
  </div>
)
