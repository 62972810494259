import React from 'react'
import styled from 'styled-components'
import { colors, md } from '../common'

export default () => (
  <Wrap>
    <h1>COLOR</h1>
    <p>Natural diamonds come in many different hues and colors. For typical diamond color grading, the scale ranges from colorless (the rarest) to light yellow or light brown. Diamonds come in other colors such as blue, pink, red and green, but are graded as “fancy” colored diamonds and have a separate grading scale.</p>
    <p>Underwood’s grades color by using a master set of diamonds which have been previously graded by a reputable gem lab. The diamond grader must be skilled and experienced to select the correct grade according to the saturation and hue.</p>
    <p>After the color scale ends at Z (or 10) the diamonds are considered to be a “fancy-color.” Natural diamonds of different colors such as red, pink, blue and green are also considered to be fancy-colored diamonds. There is actually no such thing as a “canary” or “cognac” colored diamond, but instead are proprietary branded terms of diamond companies.</p>
    <div >
      <Image src="https://s3.amazonaws.com/underwoods/Diamond+Calculator/colorScale/color01.png" alt="colorless diamond" />
      <Image src="https://s3.amazonaws.com/underwoods/Diamond+Calculator/colorScale/color02.png" alt="colorless diamond" />
      <Image src="https://s3.amazonaws.com/underwoods/Diamond+Calculator/colorScale/color03.png" alt="colorless diamond" />
      <Image src="https://s3.amazonaws.com/underwoods/Diamond+Calculator/colorScale/color04.png" alt="colorless diamond" />
      <Image src="https://s3.amazonaws.com/underwoods/Diamond+Calculator/colorScale/color05.png" alt="colorless diamond" />
      <Image src="https://s3.amazonaws.com/underwoods/Diamond+Calculator/colorScale/color06.png" alt="colorless diamond" />
    </div>
    <br /><br /><br />
    <h1>COLOR SCALE</h1>
    <div css={`display:flex;@media(max-width:970px){flex-direction:column}`}>

      <div css={`flex:1;`}>
        <div css={`display:flex;margin: 0 .5px;`}>
          <Num>0.0</Num>
          <Num>0.5</Num>
          <Num>1.0</Num>
        </div>
        <Type>
          <p>Colorless</p>
        </Type>
        <div css={`display:flex;margin: 0 .5px;`}>
          <Num>D</Num>
          <Num>E</Num>
          <Num>F</Num>
        </div>
        </div>

        <div css={`flex:1;`}>
          <div css={`display:flex;margin: 0 .5px;`}>
            <Num>1.5</Num>
            <Num>2.0</Num>
            <Num>2.5</Num>
            <Num>3.0</Num>
            </div>
            <Type>
              <p>Near Colorless</p>
            </Type>
            <div css={`display:flex;margin: 0 .5px;`}>
              <Num>G</Num>
              <Num>H</Num>
              <Num>I</Num>
              <Num>J</Num>
            </div>
          </div>

          <div css={`flex:1;`}>
            <div css={`display:flex;margin: 0 .5px;`}>
              <Num>3.5</Num>
              <Num>4.0</Num>
              <Num>4.5</Num>
            </div>
            <Type>
              <p>Faint</p>
            </Type>
            <div css={`display:flex;margin: 0 .5px;`}>
              <Num>K</Num>
              <Num>L</Num>
              <Num>M</Num>
            </div>
            </div>

            <div css={`flex:2;`}>
              <div css={`display:flex;margin: 0 .5px;`}>
                <Num>5.0</Num>
                <Num>5.5</Num>
                <Num>6.0</Num>
                <Num>6.5</Num>
                <Num>7.0</Num>
                </div>
                <Type>
                  <p>Very Light</p>
                </Type>
                <div css={`display:flex;margin: 0 .5px;`}>
                  <Num>N</Num>
                  <Num>O</Num>
                  <Num>P</Num>
                  <Num>Q</Num>
                  <Num>R</Num>
                </div>
              </div>

              <div css={`flex:3;`}>
                <div css={`display:flex;margin: 0 .5px;`}>
                  <Num>7.5</Num>
                  <Num>8.0</Num>
                  <Num>8.5</Num>
                  <Num>9.0</Num>
                  <Num>9.5</Num>
                  <Num>10.0</Num>

                  <Num>TO</Num>
                  </div>
                  <Type>
                    <p>Light</p>
                  </Type>
                  <div css={`display:flex;margin: 0 .5px;`}>
                    <Num>S</Num>
                    <Num>T</Num>
                    <Num>U</Num>
                    <Num>V</Num>
                    <Num>W</Num>
                    <Num>X</Num>
                    <Num>Y</Num>
                    <Num>Z</Num>
                  </div>
                </div>

                <div css={`flex:1;`}>
                  <div css={`display:flex;margin: 0 .5px;`}>
                    <Num >Fancy Yellow</Num>
                  </div>
                  <Type>
                    <p >Fancy Yellow</p>
                  </Type>
                  <div css={`display:flex;margin: 0 .5px;`}>
                    <Num >Fancy Yellow</Num>
                  </div>
                  </div>

      </div>
      <br /><br />
    <p>Colorless (GIA D-F, AGS 0.0-1.0): These diamonds have no color at all and are the rarest. Therefore, they demand the highest prices.
Near Colorless (GIA G-J, AGS 1.5-3.0): These diamonds have very little color and when mounted can seem colorless.
Faint Color (GIA K-M, AGS 3.5-4.5): These diamonds show some color and will be priced less on the market.
Very Light Color (GIA N-R, AGS 5.0-7.0): These diamonds show color which is easily seen in yellow hues. They will be priced even lower
Light Color (GIA S-Z, AGS 7.5-10): These diamonds have a light yellow to brown tint and will be priced the lowest on the market.</p>
  </Wrap>
)

const Wrap = styled.div`
  background-color: ${colors.green};
  color:white;
  text-align:center;
  padding: 8rem;

  ${md} {
    padding: 1rem;
  }
`

const Num = styled.p`
  background-color:white;
  color:black;
  padding:.25rem;
  margin:.5px;
  width:100%;
  border:2px solid white;
  font-size:10px;
`

const Type = styled.div`
  background-color:${colors.greyDarkest};
  padding:.25rem;
  margin:.5px;
  color:white;
  font-size:10px;
`

const Image = styled.img`
  padding:1rem;
`
