import React from 'react'
import styled from 'styled-components'
import { colors, md } from '../common'

export default () => (
  <Wrap>
    <h1>CLARITY</h1>
    <p>The definition of clarity according to Merriam-Webster is the quality or state of being clear. Diamonds without any inclusions are extremely rare and demand the highest prices. Most diamond characteristics cannot be seen without magnification. When grading clarity, it is the industry standard to do so with a microscope at 10 power (10x) magnification.</p>
    <p>While Underwood’s has a huge selection of loose diamonds and engagement rings, we do not believe in selling anything that will affect the durability of a diamond and therefore only sell diamonds with clarities of SI2 or higher.</p>
    <p>You should always buy a diamond with a grading report from a reputable gem lab such as AGS, GIA or the Forevermark Diamond Institute. On these reports there may be a plot of your diamond which shows the types of inclusions and their definitions. This provides you with a map that is unique to your diamond and can be used for identification if ever in question.</p>
  </Wrap>
)

const Wrap = styled.div`
  background-color:${colors.greyDarkest};
  color:white;
  text-align:center;
  padding:4rem 8rem;

  ${md} {
    padding: 1rem;
  }
`
