import React from 'react'
import styled from 'styled-components'
import { md } from '../common'

export default () => (
  <Wrap id="grow-diamond">
    <Copy>
      <h1>GROW A DIAMOND</h1>
      <br />
      <p>I bet you didn’t know diamonds could grow! At Underwood’s we have a trade-in program called “Grow a Diamond.” This means you can buy a diamond today and then trade it in for the amount you paid for it at any time in the future towards something larger. It can be $1 more or $10,000 more, and you can trade it in tomorrow or in twenty years. This applies to solitaire engagement rings, loose diamonds, the center diamond in most engagement rings, diamond solitaire pendants and diamond stud earrings. We like to think that as you get older, your diamonds should get bigger!</p>
      <br />
      <p><strong>Here’s an example of how it works:</strong></p>
      <br />
      <p>• You purchased a pair of 1.00ct. total weight diamond studs last December for $3000 •</p>
      <p>• You now want a pair of 1.50ct. total weight diamond studs which are $5900 •</p>
      <p>• We give you credit for $3000, taking the 1.00ct. pair back •</p>
      <p>• You owe the difference of $2900 •</p>
      <p>There is no time limit to this program. Just think, you can get a bigger diamond each year if you wanted! Start your daughter with a small pair of diamond studs and walk her down the aisle at her wedding with 1.00ct. in each ear!</p>
    </Copy>
  </Wrap>
)

const Wrap = styled.div`
  background: url("https://s3.amazonaws.com/underwoods/Diamond+Education/growadiamond-bg.jpg");
  background-size: cover;
  background-position: center top;
  padding: 4rem;
  color: white;
  text-align: center;

  ${md} {
    padding: 1rem;
  }
`

const Copy = styled.div`
  width:60%;
  margin-left:auto;
  padding: 1rem;
  line-height:24px;

  h1 {
    line-height:32px;
  }

  ${md} {
    padding:0;
    margin:0;
    width:100%;
  }
`
